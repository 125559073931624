'use client';

import { captureException } from '@sentry/nextjs';
import NextError from 'next/error';
import { useEffect } from 'react';

/**
 * A not-found component that logs the error to Sentry.
 * @param params the parameters being passed to the page.
 * @param params.error the error instance that was thrown.
 * @returns A Next.js page.
 */
export default function NotFound({
  error
}: {
  error: Error;
}) {
  useEffect(() => {
    captureException(error);
  }, [error]);
  return <NextError statusCode={404} data-sentry-element="NextError" data-sentry-component="NotFound" data-sentry-source-file="not-found.tsx" />;
}